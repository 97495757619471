import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Cart.css";

const Cart = ({
  cartItems,
  removeFromCart,
  updateQuantity,
  setTotalAmount,
}) => {
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const groupedCartItems = cartItems
    .reduce((groups, item) => {
      const existingItem = groups.find(
        (group) => group.id === item.id && group.color === item.color
      );
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        groups.push({ ...item });
      }
      return groups;
    }, [])
    .filter((item) => item.quantity > 0);

  const totalAmount = groupedCartItems.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  useEffect(() => {
    const calculatedTotalAmount = groupedCartItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    setTotalAmount(calculatedTotalAmount); // Ustawienie totalAmount za pomocą przekazanej funkcji
  }, [groupedCartItems, setTotalAmount]);

  return (
    <div className="cart-container">
      <div className="cart">
        <h2>Koszyk</h2>
        {groupedCartItems.length === 0 ? (
          <p>Koszyk jest pusty.</p>
        ) : (
          <div>
            <ul>
              {groupedCartItems.map((item) => (
                <li key={item.id}>
                  {item.name} - {item.size}, Kolor: {item.color}{" "}
                  <span>{item.price * item.quantity}</span> zł
                  <div>
                    <button
                      onClick={() =>
                        updateQuantity(item.id, item.quantity - 1, item.color)
                      }
                    >
                      -
                    </button>
                    {item.quantity}
                    <button
                      onClick={() =>
                        updateQuantity(item.id, item.quantity + 1, item.color)
                      }
                    >
                      +
                    </button>
                  </div>
                  <button onClick={() => removeFromCart(item.id, item.color)}>
                    Usuń z Koszyka
                  </button>
                </li>
              ))}
            </ul>
            <p>
              Łączna cena: <span>{totalAmount} zł</span>
            </p>
            <NavLink to="/formularz-dostawy">
              <button className="important">Kontynuuj Zakupy</button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
