import React from "react";
import Slider from "./slider/Slider";
import Products from "./products/Products";

const Home = () => {
  return (
    <div>
      <Slider />
      <Products />
    </div>
  );
};

export default Home;
