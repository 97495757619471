import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Stylizacja dla edytora Quill
import "./AdminPanel.css";

const SuperAdmin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [products, setProducts] = useState([]);
  const [password, setPassword] = useState("");
  const [newProduct, setNewProduct] = useState({
    name: "",
    prices: "",
    photo: "",
    order: "",
    description: "", // Pole dla Quill
    variants: [],
  });
  const [editProduct, setEditProduct] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const isAuthenticated = () =>
    localStorage.getItem("authenticated") === "true";

  useEffect(() => {
    if (isAuthenticated()) {
      setLoggedIn(true);
      axios
        .get("https://bkserver.stronawcal.pl/admin/products")
        .then((response) => setProducts(response.data))
        .catch((error) => console.error("Error fetching products:", error));
    }
  }, []);

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://bkserver.stronawcal.pl/admin",
        { password }
      );

      setMessage(response.data.message);
      setError("");
      localStorage.setItem("authenticated", "true");
      setLoggedIn(true);
      navigate("/");
    } catch (error) {
      setMessage("");
      setError("Invalid password");
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    const maxId = products.reduce((max, product) => {
      return product.id > max ? product.id : max;
    }, 0);

    const productWithId = {
      ...newProduct,
      id: maxId + 1,
    };

    try {
      await axios.post(
        "https://bkserver.stronawcal.pl/admin/products",
        productWithId
      );
      setMessage("Produkt dodany pomyślnie!");
      setError("");

      setProducts([...products, productWithId]);

      setNewProduct({
        name: "",
        prices: "",
        photo: "",
        order: "",
        description: "",
        variants: [],
      });
      navigate("/");
    } catch (error) {
      setMessage("");
      setError("Błąd podczas dodawania produktu");
    }
  };

  const handleEditProduct = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://bkserver.stronawcal.pl/admin/products/${editProduct.id}`,
        editProduct
      );
      setMessage("Product updated successfully!");
      setError("");
      setEditProduct(null);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === editProduct.id ? editProduct : product
        )
      );
    } catch (error) {
      setMessage("");
      setError("Error updating product");
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await axios.delete(`https://bkserver.stronawcal.pl/admin/products/${id}`);
      setProducts(products.filter((product) => product.id !== id));
      setMessage("Produkt Został Usunięty!");
      setError("");
    } catch (error) {
      setMessage("");
      setError("Wystąpił Błąd");
    }
  };

  const handleVariantChange = (index, e) => {
    const { name, value } = e.target;
    const updatedVariants = [...editProduct.variants];
    updatedVariants[index] = {
      ...updatedVariants[index],
      [name]: value,
    };
    setEditProduct({
      ...editProduct,
      variants: updatedVariants,
    });
  };

  const handleAddVariant = () => {
    setEditProduct({
      ...editProduct,
      variants: [
        ...editProduct.variants,
        {
          id: Date.now(),
          dimension: "",
          colors: [],
          price: "",
          hasDrawers: false,
          photos: {},
        },
      ],
    });
  };

  const handleRemoveVariant = (index) => {
    setEditProduct({
      ...editProduct,
      variants: editProduct.variants.filter((_, i) => i !== index),
    });
  };

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }], // Narzędzia do zmiany koloru tekstu i tła
      ["link"],
      ["clean"],
    ],
  };

  const quillFormats = [
    "header",
    "font",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "color",
    "background",
    "link",
  ];

  return (
    <div className="sadmin_container">
      <nav className="sadmin_nav">
        {loggedIn ? (
          <></>
        ) : (
          <Link className="sadmin_link" to="/admin">
            Zaloguj się
          </Link>
        )}
      </nav>

      {!loggedIn ? (
        <div className="sadmin_login">
          <h2 className="sadmin_heading">Logowanie</h2>
          <form
            className="sadmin_form"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <input
              className="sadmin_input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <button className="sadmin_button" type="submit">
              Zaloguj się
            </button>
          </form>
          {message && (
            <p className="sadmin_message sadmin_message_success">{message}</p>
          )}
          {error && (
            <p className="sadmin_message sadmin_message_error">{error}</p>
          )}
        </div>
      ) : (
        <div className="sadmin_products">
          <h2 className="sadmin_heading">Lista Przedmiotów</h2>
          <ul className="sadmin_product_list">
            {products.map((product) => (
              <li className="sadmin_product_item" key={product.id}>
                {product.name} - {product.prices}
                <button
                  className="sadmin_button sadmin_delete_button"
                  onClick={() => handleDeleteProduct(product.id)}
                >
                  Usuń Produkt
                </button>
                <button
                  className="sadmin_button"
                  onClick={() => setEditProduct(product)}
                >
                  Edytuj Dane Produktu
                </button>
                {editProduct && editProduct.id === product.id && (
                  <div className="sadmin_edit_product">
                    <h3 className="sadmin_heading">Panel Edycji</h3>
                    <form className="sadmin_form" onSubmit={handleEditProduct}>
                      <h4>Nazwa Szafy</h4>
                      <input
                        className="sadmin_input"
                        type="text"
                        value={editProduct.name || ""}
                        onChange={(e) =>
                          setEditProduct({
                            ...editProduct,
                            name: e.target.value,
                          })
                        }
                        placeholder="Product Name"
                        required
                      />
                      <h4>Przedział Cenowy</h4>
                      <input
                        className="sadmin_input"
                        type="text"
                        value={editProduct.prices || ""}
                        onChange={(e) =>
                          setEditProduct({
                            ...editProduct,
                            prices: e.target.value,
                          })
                        }
                        placeholder="Prices"
                        required
                      />
                      <h4>Zdjęcie Produktu na stronie głównej</h4>
                      <input
                        className="sadmin_input"
                        type="text"
                        value={editProduct.photo || ""}
                        onChange={(e) =>
                          setEditProduct({
                            ...editProduct,
                            photo: e.target.value,
                          })
                        }
                        placeholder="Photo Filename"
                      />
                      <h4>Dostępny na zamówienie??</h4>
                      <input
                        className="sadmin_input"
                        type="text"
                        value={editProduct.order || ""}
                        onChange={(e) =>
                          setEditProduct({
                            ...editProduct,
                            order: e.target.value,
                          })
                        }
                        placeholder="wpisz tutaj 'only' jeśli produkt ma być dostępny tylko na zamówienie"
                      />
                      <h4>Opis Produktu</h4>
                      <ReactQuill
                        theme="snow"
                        value={editProduct.description || ""}
                        onChange={(value) =>
                          setEditProduct({
                            ...editProduct,
                            description: value,
                          })
                        }
                        modules={quillModules}
                        formats={quillFormats}
                        placeholder="Product Description"
                      />
                      <h4 className="sadmin_subheading">Warianty</h4>
                      {editProduct.variants.map((variant, index) => (
                        <div
                          className="sadmin_variant"
                          key={variant.id || index}
                        >
                          <h4>Wymiary</h4>
                          <input
                            className="sadmin_input"
                            type="text"
                            name="dimension"
                            value={variant.dimension || ""}
                            onChange={(e) => handleVariantChange(index, e)}
                            placeholder="szerokość x wysokość x głębokość"
                          />
                          <h4>Cena Szafy</h4>
                          <input
                            className="sadmin_input"
                            type="number"
                            name="price"
                            value={variant.price || ""}
                            onChange={(e) => handleVariantChange(index, e)}
                            placeholder="Cena (tego wymiaru)"
                          />
                          <button
                            className="sadmin_button sadmin_remove_button"
                            type="button"
                            onClick={() => handleRemoveVariant(index)}
                          >
                            Usuń Wariant
                          </button>
                          <h5 className="sadmin_subheading">Kolory</h5>
                          <input
                            className="sadmin_input"
                            type="text"
                            value={variant.colors.join(", ") || ""}
                            onChange={(e) => {
                              const colors = e.target.value
                                .split(",")
                                .map((color) => color.trim());
                              handleVariantChange(index, {
                                target: { name: "colors", value: colors },
                              });
                            }}
                            placeholder="Kolory (podawaj po przecinku)"
                          />
                          <h5 className="sadmin_subheading">Zdjęcia</h5>
                          {Object.entries(variant.photos).map(
                            ([color, photo], photoIndex) => (
                              <div
                                key={photoIndex}
                                className="sadmin_photo_entry"
                              >
                                <input
                                  className="sadmin_input"
                                  type="text"
                                  value={photo}
                                  onChange={(e) => {
                                    const photos = { ...variant.photos };
                                    photos[color] = e.target.value;
                                    handleVariantChange(index, {
                                      target: { name: "photos", value: photos },
                                    });
                                  }}
                                  placeholder={`Zdjęcie dla ${color}`}
                                />
                              </div>
                            )
                          )}
                          <button
                            className="sadmin_button"
                            type="button"
                            onClick={() => {
                              const color = prompt("Wprowadź kolor:");
                              if (color) {
                                const photos = {
                                  ...variant.photos,
                                  [color]: "",
                                };
                                handleVariantChange(index, {
                                  target: { name: "photos", value: photos },
                                });
                              }
                            }}
                          >
                            Dodaj Zdjęcie
                          </button>
                        </div>
                      ))}
                      <button
                        className="sadmin_button"
                        type="button"
                        onClick={handleAddVariant}
                      >
                        Dodaj Wariant
                      </button>
                      <button className="sadmin_button" type="submit">
                        Zapisz Zmiany
                      </button>
                    </form>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <h2 className="sadmin_heading">Dodaj Nowy Produkt</h2>
          <form className="sadmin_form" onSubmit={handleAddProduct}>
            <input
              className="sadmin_input"
              type="text"
              value={newProduct.name}
              onChange={(e) =>
                setNewProduct({ ...newProduct, name: e.target.value })
              }
              placeholder="Nazwa Produktu"
              required
            />
            <input
              className="sadmin_input"
              type="text"
              value={newProduct.prices}
              onChange={(e) =>
                setNewProduct({ ...newProduct, prices: e.target.value })
              }
              placeholder="Zakres cenowy np. '1100-2000"
              required
            />
            <input
              className="sadmin_input"
              type="text"
              value={newProduct.photo}
              onChange={(e) =>
                setNewProduct({ ...newProduct, photo: e.target.value })
              }
              placeholder="Zdjęcie (na stronie głównej)"
            />
            <input
              className="sadmin_input"
              type="text"
              value={newProduct.order}
              onChange={(e) =>
                setNewProduct({ ...newProduct, order: e.target.value })
              }
              placeholder="wpisz tutaj 'only' jeśli produkt jest tylko na zamówienie"
            />
            <ReactQuill
              theme="snow"
              value={newProduct.description || ""}
              onChange={(value) =>
                setNewProduct({ ...newProduct, description: value })
              }
              modules={quillModules}
              formats={quillFormats}
              placeholder="Opis Produktu"
            />
            <button className="sadmin_button" type="submit">
              Add Product
            </button>
          </form>
          {message && (
            <p className="sadmin_message sadmin_message_success">{message}</p>
          )}
          {error && (
            <p className="sadmin_message sadmin_message_error">{error}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SuperAdmin;
