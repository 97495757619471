import React, { useEffect, useState } from "react";
import "./Slider.css";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { sliderItems } from "../../data";

const Arrow = styled.div`
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
`;
const Image = styled.img``;

const Container = styled.div``;

const Wrapper = styled.div`
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [press, setPress] = useState(0);
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
      setPress(1);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
      setPress(1);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (press === 1) {
        setPress(0);
      } else {
        setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
      }
    }, 20000);
  });
  return (
    <Container className="container">
      <Arrow
        className="arrow"
        direction="left"
        onClick={() => handleClick("left")}
      >
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper className="wrapper" slideIndex={slideIndex}>
        {sliderItems.map((item) => (
          <div className="slide">
            <div className="img-container">
              <Image className="img" src={item.img} alt={item.id} />
            </div>
            <div className="info-container">
              <div className="title">{item.title}</div>
              <div className="description">{item.desc}</div>
            </div>
          </div>
        ))}
      </Wrapper>
      <Arrow
        className="arrow"
        direction="right"
        onClick={() => handleClick("right")}
      >
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

export default Slider;
