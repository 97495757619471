import React, { useState, useEffect } from "react";
import "./Products.css"; // Load appropriate CSS styles
import { Link } from "react-router-dom";

function Products() {
  const [produkty, setProdukty] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://bkserver.stronawcal.pl/produkty");
        if (!response.ok) {
          console.log(response);
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProdukty(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <div className="produkty-container">
        {produkty.map((produkt, index) => (
          <div
            className={`produkt ${index % 2 === 0 ? "even" : "odd"}`}
            key={produkt.id}
          >
            <Link
              to={`/produkty/${produkt.id}-${produkt.name
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
            >
              <img src={`img/${produkt.photo}`} alt={produkt.name} />
              <h2>{produkt.name}</h2>
              <p>{produkt.prices}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
