import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-buttons">
          <button className="footer-button">
            <a href="/regulamin.docx" download>
              Regulamin
            </a>
          </button>
          <button className="footer-button">
            {" "}
            <a href="/polityka.docx" download>
              Polityka Prywaności
            </a>
          </button>
        </div>
        <p className="footer-text">
          © 2023 Bizkar. Wszelkie prawa zastrzeżone.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
