import React, { useState, useEffect } from "react";
import "./Confirm.css";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const OrderSummary = ({
  cartItems,
  formData,
  totalAmount,
  setCartItems,
  setTotalAmount,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("przelew");
  const [shippingOption, setShippingOption] = useState("kurier");
  const [deliveryCost, setDeliveryCost] = useState(200);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [orderConfirmation, setOrderConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (shippingOption === "kurier") {
      setDeliveryCost(200);
    } else if (shippingOption === "odbior") {
      setDeliveryCost(0);
    }
  }, [shippingOption]);

  useEffect(() => {
    if (
      cartItems.length === 0 ||
      !formData.fullName ||
      !formData.street ||
      !formData.houseNumber ||
      !formData.postalCode ||
      !formData.city ||
      !formData.phoneNumber ||
      !formData.email
    ) {
      navigate("/");
    }
  }, [cartItems, formData, navigate]);

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleShippingChange = (e) => {
    setShippingOption(e.target.value);
  };

  const resetCart = () => {
    setCartItems([]);
    setTotalAmount(0);
  };

  const handleSubmit = async () => {
    if (paymentMethod && shippingOption) {
      setProcessingOrder(true);

      const orderData = {
        cartItems,
        formData,
        totalAmount,
        paymentMethod,
        shippingOption,
        deliveryCost,
      };

      try {
        console.log(orderData.deliveryCost);
        const response = await axios.post(
          "https://bkserver.stronawcal.pl/order",
          orderData
        );

        if (response.status === 200) {
          console.log("Zamówienie zostało przesłane.");
          setTimeout(() => {
            setProcessingOrder(false);
            setOrderConfirmation(true);
            resetCart();
          }, 2000);

          setTimeout(() => {
            navigate("/");
          }, 5000);
        } else {
          console.error("Wystąpił błąd podczas przesyłania zamówienia.");
          setProcessingOrder(false);
        }
      } catch (error) {
        console.error("Wystąpił błąd podczas przesyłania zamówienia:", error);
        setProcessingOrder(false);
      }
    } else {
      console.log("Proszę wybrać opcję płatności i dostawy.");
    }
  };

  return (
    <div className="container">
      <div className="order-summary">
        {orderConfirmation ? (
          <div className="order-confirmation">
            <p className="confirm-text">
              Dziękujemy za zakup! Twoje zamówienie jest przetwarzane.
            </p>
            <button>
              <NavLink
                to="/"
                style={{ textDecoration: "none" }}
                activeClassName="selected"
              >
                <p className="navbar-link">Strona Główna</p>
              </NavLink>
            </button>
          </div>
        ) : (
          <>
            <h2>Podsumowanie zamówienia</h2>
            <div className="products">
              <h3>Produkty w koszyku:</h3>
              <ul>
                {cartItems.map((item) => (
                  <li key={item.id}>
                    <p>{item.name}</p> Rozmiar: {item.size},
                    <p> Kolor: {item.color}</p> Ilość: {item.quantity}
                  </li>
                ))}
              </ul>
            </div>
            <div className="total-amount">
              <h3>Łączna cena zamówienia: {totalAmount} zł</h3>
            </div>
            <div className="delivery-info">
              <h3>Informacje o dostawie:</h3>
              <p>
                <strong>Imię i Nazwisko:</strong> {formData.fullName}
              </p>
              <p>
                <strong>Adres:</strong> {formData.street} {formData.houseNumber}
                ,&nbsp;
                {formData.houseMiniNumber}&nbsp;,{formData.postalCode}{" "}
                {formData.city}
              </p>
              <p>
                <strong>Numer telefonu:</strong> {formData.phoneNumber}
              </p>
              <p>
                <strong>Adres e-mail:</strong> {formData.email}
              </p>
              {formData.companyName && (
                <p>
                  <strong>Nazwa Firmy:</strong> {formData.companyName}
                </p>
              )}
              {formData.country && (
                <p>
                  <strong>Kraj:</strong> {formData.country}
                </p>
              )}
            </div>
            <div className="payment-method">
              <h3>Wybierz metodę płatności:</h3>
              <select value={paymentMethod} onChange={handlePaymentChange}>
                <option value="przelew">Przelew</option>
                <option value="gotówka">Gotówka</option>
              </select>
            </div>
            <div className="shipping-option">
              <h3>Wybierz opcję dostawy:</h3>
              <select value={shippingOption} onChange={handleShippingChange}>
                <option value="kurier">
                  Przesyłka kurierska 200zł (netto)
                </option>
                <option value="odbior">Odbiór osobisty</option>
              </select>
            </div>
            <h3>
              Łączna cena zamówienia z dostawą: {totalAmount + deliveryCost} zł
            </h3>
            <div className="order-button">
              {processingOrder ? (
                <p>Proszę czekać...</p>
              ) : (
                <button onClick={handleSubmit} disabled={processingOrder}>
                  Złóż Zamówienie
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
