import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import "./Product.css";
import ImageSlider from "./ImageSlider";

const ProductDetail = ({ addToCart }) => {
  const { id } = useParams();
  const [produkt, setProdukt] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [productAdded, setProductAdded] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const handleContactClick = () => {
    setShowPhoneNumber(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch("https://bkserver.stronawcal.pl/produkty");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const selectedProdukt = data.find((item) => item.id === parseInt(id, 10));
      setProdukt(selectedProdukt);
      setSelectedSize(selectedProdukt.variants[0].dimension);
      setSelectedColor(selectedProdukt.variants[0].colors[0]);
      setSelectedVariant(selectedProdukt.variants[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSizeChange = (event) => {
    const newSize = event.target.value;
    setSelectedSize(newSize);
    setProductAdded(false);
    const newVariant = produkt.variants.find(
      (variant) =>
        variant.dimension === newSize && variant.colors.includes(selectedColor)
    );
    setSelectedVariant(newVariant);
  };

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
    setProductAdded(false);
    const newVariant = produkt.variants.find(
      (variant) =>
        variant.dimension === selectedSize && variant.colors.includes(newColor)
    );
    setSelectedVariant(newVariant);
  };

  const handleAddToCart = () => {
    if (selectedVariant) {
      addToCart({
        id: selectedVariant.id,
        name: produkt.name,
        size: selectedSize,
        color: selectedColor,
        price: selectedVariant.price,
        quantity: quantity,
      });
      setProductAdded(true);
    } else {
      // Obsłuż błąd - użytkownik nie wybrał rozmiaru lub koloru
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (!produkt) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="product-detail">
        <div className="product-image">
          <img
            src={`/img/${
              selectedVariant.photos[selectedColor] || produkt.photo
            }`}
            alt={produkt.name}
          />
        </div>
        <div className="product-info">
          <h2>{produkt.name}</h2>
          <label>Wybierz rozmiar szafy:</label>
          <select
            className="product-select"
            value={selectedSize}
            onChange={handleSizeChange}
          >
            {produkt.variants
              .filter((variant) => variant.colors.includes(selectedColor))
              .map((variant) => (
                <option key={variant.id} value={variant.dimension}>
                  {variant.dimension}
                </option>
              ))}
          </select>
          <label>Wybierz kolor:</label>
          <select
            className="product-select"
            value={selectedColor}
            onChange={handleColorChange}
          >
            {produkt.variants[0].colors.map((color, index) => (
              <option key={index} value={color}>
                {color}
              </option>
            ))}
          </select>
          <p>
            Cena:{" "}
            {selectedVariant
              ? selectedVariant.price
              : "Wybierz rozmiar i kolor"}{" "}
            zł
          </p>
          <label>Ilość:</label>
          <input
            type="number"
            value={quantity}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value >= 0) {
                setQuantity(value);
              }
            }}
            min="0"
          />
          {produkt.order === "only" ? (
            isMobile ? (
              <a href="tel:+48533122848">
                <button>Zadzwoń: +48 533 122 848</button>
              </a>
            ) : (
              <button onClick={handleContactClick}>
                {showPhoneNumber
                  ? "Zadzwoń: +48 533 122 848"
                  : "Skontaktuj się"}
              </button>
            )
          ) : productAdded ? (
            <p>Produkt został dodany do Koszyka</p>
          ) : (
            <button onClick={handleAddToCart}>Dodaj do koszyka</button>
          )}
          {productAdded && (
            <NavLink to="/koszyk">
              <button>Przejdź do koszyka</button>
            </NavLink>
          )}
        </div>
      </div>
      <div className="product-description">
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: produkt.description }}
        ></div>
      </div>
      <ImageSlider />
    </>
  );
};

export default ProductDetail;
