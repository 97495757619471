import React, { useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import styled from "styled-components";
import "./ImageSlider.css";

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.slideIndex * -100}%);
`;

const Slide = styled.div`
  min-width: 100%;
  transition: transform 0.5s ease;
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: var(--red);
  color: var(--red);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 2;
  ${(props) => (props.direction === "left" ? "left: 10px" : "right: 10px")};
  svg {
    color: var(--red);
  }
`;
const images = [
  "/img/wm1.jpeg",
  "/img/wm2.jpeg",
  "/img/wm3.jpeg",
  "/img/wm4.jpeg",
  "/img/wm5.jpeg",
];

const ImageSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handlePrevSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNextSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <SliderContainer>
      <Arrow direction="left" onClick={handlePrevSlide}>
        <ArrowLeftOutlined style={{ color: "white" }} />
      </Arrow>
      <SliderWrapper slideIndex={slideIndex}>
        {images.map((img, index) => (
          <Slide key={index}>
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%" }}
            />
          </Slide>
        ))}
      </SliderWrapper>
      <Arrow direction="right" onClick={handleNextSlide}>
        <ArrowRightOutlined style={{ color: "white" }} />
      </Arrow>
    </SliderContainer>
  );
};

export default ImageSlider;
