import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Custom.css";

const Custom = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [seconds, setSeconds] = useState(4); // Początkowa liczba sekund
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (confirmationSent && seconds > 0) {
      const countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else if (seconds === 0) {
      navigate("/");
    }
  }, [confirmationSent, seconds, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setIsWaiting(true); // Ustaw flagę oczekiwania na true

    // Wysyłanie danych na serwer
    fetch("https://bkserver.stronawcal.pl/send-email", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          console.log("Żądanie odebrane na serwerze");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.message);
        setResponseMessage(data.message);
        setConfirmationSent(true);
      })
      .catch((error) => {
        console.error("Błąd:", error);
        // Dodaj obsługę błędu, na przykład wyświetlanie komunikatu dla użytkownika
      })
      .finally(() => {
        setIsWaiting(false); // Ustaw flagę oczekiwania na false po otrzymaniu odpowiedzi
      });
  };

  return (
    <div className="container-contact">
      <div className="header">Potrzebujesz Mebli na Wymiar?</div>
      <div className="span">Napisz do nas</div>
      <div className="form">
        {isWaiting ? (
          <div className="waiting-message">
            <div className="header">Poczekaj...</div>
          </div>
        ) : confirmationSent ? (
          <div className="confirmation-message">
            <div className="header">{responseMessage}</div>
            <div className="span">
              Zostaniesz przekierowany za {seconds} sekund
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="form-text">Imię:</div>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <div className="form-text">Adres Email:</div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <div className="form-text">Treść wiadomości:</div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </div>

            <button
              className="custom"
              type="submit"
              disabled={isButtonDisabled}
            >
              Wyślij
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Custom;
