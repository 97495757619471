import React, { useState, useEffect } from "react";
import "./ShippingForm.css"; // Importuj plik ze stylami
import { useNavigate } from "react-router-dom";

const ShippingForm = ({ onFormSubmit }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    street: "",
    houseNumber: "",
    houseMiniNumber: "",
    postalCode: "",
    city: "",
    phoneNumber: "",
    email: "",
    country: "Polska", // Dodatkowe pole dla paczek zagranicznych
  });

  const navigate = useNavigate();

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []); // Załaduj dane z localStorage przy pierwszym renderowaniu

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sprawdź, czy wszystkie wymagane pola są uzupełnione
    const requiredFields = [
      "fullName",
      "street",
      "houseNumber",
      "postalCode",
      "city",
      "phoneNumber",
      "email",
    ];

    const isFormValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (isFormValid) {
      onFormSubmit(formData);
      navigate("/podsumowanie");
    } else {
      // Wyświetl komunikat o błędzie lub zaimplementuj inne zachowanie dla niepoprawnego formularza
      console.log("Wypełnij wszystkie wymagane pola!");
    }
  };

  return (
    <div className="all">
      <div className="form-container">
        <div className="header-s header">Formularz Dostawy</div>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="fullName" className="form-label">
                Imię i Nazwisko
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className="form-input-shipping"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyName" className="form-label">
                Nazwa Firmy (opcjonalnie):
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                className="form-input-shipping"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="street" className="form-label">
                Ulica:
              </label>
              <input
                type="text"
                id="street"
                name="street"
                value={formData.street}
                onChange={handleInputChange}
                className="form-input-shipping"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="houseNumber" className="form-label">
                Numer Domu
              </label>
              <input
                type="text"
                id="houseNumber"
                name="houseNumber"
                value={formData.houseNumber}
                onChange={handleInputChange}
                className="form-input-shipping"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="postalCode" className="form-label">
                Kod pocztowy:
              </label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                className="form-input-shipping"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="city" className="form-label">
                Miejscowość:
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="form-input-shipping"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="phoneNumber" className="form-label">
                Numer telefonu:
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="form-input-shipping phone"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Adres e-mail:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-input-shipping email"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="houseNumber" className="form-label">
                Numer Mieszkania (opcjonalnie)
              </label>
              <input
                type="text"
                id="houseMiniNumber"
                name="houseMiniNumber"
                value={formData.houseMiniNumber}
                onChange={handleInputChange}
                className="form-input-shipping"
              />
            </div>
            <div className="form-group">
              <label htmlFor="country" className="form-label">
                Kraj
              </label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="form-input-shipping"
              />
            </div>
          </div>
          <div className="form-row">
            <button type="submit" className="form-button">
              Złóż Zamówienie
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingForm;
