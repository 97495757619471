import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminPanel.css";

const Admin = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState({});
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Pobierz listę zamówień z API
    axios
      .get("https://bkserver.stronawcal.pl/api/orders")
      .then((response) => setOrders(response.data))
      .catch((error) =>
        console.error("Błąd podczas pobierania zamówień:", error)
      );
  }, []);

  const handleOrderClick = (orderId) => {
    // Pobierz szczegóły zamówienia po kliknięciu na numer zamówienia
    axios
      .get(`https://bkserver.stronawcal.pl/api/orders/${orderId}`)
      .then((response) => {
        console.log("Dane zamówienia:", response.data);
        setSelectedOrders((prevSelectedOrders) => ({
          ...prevSelectedOrders,
          [orderId]: response.data,
        }));
      })
      .catch((error) =>
        console.error("Błąd podczas pobierania szczegółów zamówienia:", error)
      );
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://bkserver.stronawcal.pl/admin",
        {
          password: password,
        }
      );

      setMessage(response.data.message);
      setError("");

      localStorage.setItem("authenticated", "true");
    } catch (error) {
      setMessage("");
      setError("Błędne hasło");
    }
  };

  const isAuthenticated = () => {
    return localStorage.getItem("authenticated") === "true";
  };

  return (
    <div className="container-admin">
      {isAuthenticated() && (
        <div>
          <div>
            <h2>Lista Zamówień</h2>
            <div className="info">
              {orders.map((order) => (
                <div
                  className="infodiv"
                  key={order.id}
                  onClick={() => handleOrderClick(order.id)}
                >
                  <li>Numer Zamówienia: {order.order_number}</li>
                  <li>Całkowita Kwota: {order.total_amount}</li>
                  <li>Koszt Dostawy: {order.delivery_cost}</li>
                  <li>Metoda Płatności: {order.payment_method}</li>
                  <li>Opcja Dostawy: {order.shipping_option}</li>
                  {selectedOrders[order.id] && (
                    <ul className="sinfo">
                      <h3>Dane do Wysyłki:</h3>
                      <div className="delivery-info">
                        <li>
                          {order.customer_email} tel:{" "}
                          {order.customer_phone_number}
                        </li>
                        <li>{order.delivery_address_full_name}</li>
                        <li>
                          {order.delivery_address_street}&nbsp;
                          {order.delivery_address_house_number}
                          {order.delivery_address_house_mini_number}
                        </li>
                        <li>
                          {order.delivery_address_city}&nbsp;
                          {order.delivery_address_postal_code}
                        </li>
                        <li>{order.delivery_address_country}</li>
                      </div>
                      <h3>Produkty</h3>
                      {selectedOrders[order.id].map((item) => (
                        <div className="fade" key={item.id}>
                          <li>Rodzaj: {item.product_name}</li>
                          <li>Wymiary:{item.product_size}</li>
                          <li>Kolor: {item.product_color}</li>
                          <li>Ilość: {item.quantity}</li>
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <label>Hasło: </label>
      <input
        className="input-admin"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div></div>
      <button onClick={handleLogin}>Zaloguj</button>
      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default Admin;
