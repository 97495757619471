import React, { useState, useEffect } from "react";
import Navbar from "./components/navbar/Navbar";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Custom from "./components/custom/Custom";
import Home from "./components/home";
import Info from "./components/info/Info";
import Product from "./components/products/Product";
import Cart from "./components/cart/Cart";
import ShippingForm from "./components/shippingform/ShippingForm";
import Confirm from "./components/shippingform/Confirm";
import Footer from "./components/footer/Footer";
import NotFound from "./components/notfound/NotFound";
import Admin from "./components/adminpanel/AdminPanel";
import SuperAdmin from "./components/superadmin/SuperAdmin";

const App = () => {
  const [formData, setFormData] = useState({});

  const handleFormSubmit = (data) => {
    setFormData(data);
  };
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    saveCartToLocalStorage(cartItems, totalAmount);
  }, [cartItems, totalAmount]);

  const addToCart = (product) => {
    const existingProduct = cartItems.find(
      (item) => item.id === product.id && item.color === product.color
    );

    if (existingProduct) {
      const updatedCartItems = cartItems.map((item) =>
        item.id === product.id && item.color === product.color
          ? { ...item, quantity: item.quantity + product.quantity }
          : item
      );
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...product }]);
    }
  };

  const updateQuantity = (itemId, newQuantity, color) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId && item.color === color
        ? { ...item, quantity: newQuantity }
        : item
    );
    setCartItems(updatedCartItems);
    saveCartToLocalStorage(updatedCartItems, totalAmount);
  };

  const removeFromCart = (itemId, color) => {
    const updatedCart = cartItems.filter(
      (item) => item.id !== itemId || item.color !== color
    );
    setCartItems(updatedCart);
    saveCartToLocalStorage(cartItems, totalAmount);
  };

  useEffect(() => {
    // Pobierz dane z localStorage, jeśli istnieją
    const storedCartItems = localStorage.getItem("cartItems");
    const storedTotalAmount = localStorage.getItem("totalAmount");

    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }

    if (storedTotalAmount) {
      setTotalAmount(parseFloat(storedTotalAmount));
    }
  }, []);

  const saveCartToLocalStorage = (items, amount) => {
    localStorage.setItem("cartItems", JSON.stringify(items));
    localStorage.setItem("totalAmount", amount);
  };

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nawymiar" element={<Custom />} />
        <Route path="/informacje" element={<Info />} />
        <Route
          path="/produkty/:id"
          element={<Product addToCart={addToCart} />}
        />
        <Route
          path="/koszyk"
          element={
            <Cart
              cartItems={cartItems}
              removeFromCart={removeFromCart}
              updateQuantity={updateQuantity}
              setTotalAmount={setTotalAmount}
            />
          }
        />
        <Route
          path="/formularz-dostawy"
          element={<ShippingForm onFormSubmit={handleFormSubmit} />}
        />
        <Route
          path="/podsumowanie"
          element={
            <Confirm
              cartItems={cartItems}
              formData={formData}
              totalAmount={totalAmount}
              setCartItems={setCartItems}
              setTotalAmount={setTotalAmount}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/superadmin" element={<SuperAdmin />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
