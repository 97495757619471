import React from "react";
import "./Info.css";
const map =
  '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2491.8264847202463!2d17.925335676986208!3d51.351101222108895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471aa0ce3dbde709%3A0xc784eee73a42b075!2sRzetnia%2024%2C%2063-600%20K%C4%99pno!5e0!3m2!1spl!2spl!4v1697816139247!5m2!1spl!2spl" width="1200" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const Info = () => {
  return (
    <div>
      <div className="info-container2">
        <div className="info-details">
          <div className="header-info">BIZKAR</div>
          <div className="info-text">Karol Kasprzak</div>
          <div className="info-text">Rzetnia 24, 63-600 Kępno</div>
          <div className="info-text">Telefon: +48 533 122 848</div>
          <div className="info-text">Email: bizkar1005@gmail.com</div>
          <div className="info-text">NIP: 9970071546</div>
        </div>
        <div className="map-container">
          <Iframe iframe={map} />,
        </div>
      </div>
    </div>
  );
};

export default Info;
