export const sliderItems = [
  {
    id: 1,
    img: "img/p_black.jpg",
    title: "Meble stworzone z myślą o Tobie",
    desc: "Sprawdź naszą szeroką ofertę Mebli na Wymiar",
  },
  {
    id: 2,
    img: "img/p_white.jpg",
    title: "Wybór NAJLEPSZYCH materiałów ",
    desc: "Nasze Szafy zaskoczą cie Jakością",
  },
  {
    id: 3,
    img: "img/p_silver.jpg",
    title: "Produkt Idealny dla CIEBIE",
    desc: "Nie zwlekaj, poznaj naszą Ofertę",
  },
];
