import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ShoppingCartOutlined, Menu, Close } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import "./Navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setMobileMenuOpen(false); // Zamyka menu mobilne przy zmianie szerokości okna
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`navbar ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
      <div className="navbar-wrapper">
        <NavLink
          className="navbar-logo"
          to="/"
          style={{ textDecoration: "none" }}
        >
          <div className="navbar-logo">BIZKAR</div>
        </NavLink>

        {isMobileMenuOpen ? (
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <Close />
          </div>
        ) : (
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <Menu />
          </div>
        )}

        {/* Jedna sekcja menu */}
        <div className={`navbar-button ${isMobileMenuOpen ? "open" : ""}`}>
          <MenuItem>
            <NavLink
              to="/"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Strona Główna</p>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="nawymiar"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Meble na Wymiar</p>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="informacje"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Informacje</p>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="koszyk"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">
                <ShoppingCartOutlined />
              </p>
            </NavLink>
          </MenuItem>
        </div>
      </div>

      {/* Jedna sekcja mobilnego menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu">
          <MenuItem>
            <NavLink
              to="/"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Strona Główna</p>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/nawymiar"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Meble na Wymiar</p>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="informacje"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Informacje</p>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="koszyk"
              style={{ textDecoration: "none" }}
              activeClassName="selected"
            >
              <p className="navbar-link">Koszyk</p>
            </NavLink>
          </MenuItem>
        </div>
      )}
    </div>
  );
};

export default Navbar;
