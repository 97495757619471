import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="container-error">
      <h1 className="error">Oops! Chyba sie zgubiłeś.</h1>
      <div>
        {" "}
        <p className="error">Tutaj znajdziesz użyteczne Linki:</p>
      </div>

      <button>
        <Link className="err" to="/">
          Strona Główna
        </Link>
      </button>
      <div></div>
      <button>
        <Link className="err" to="/cart">
          Koszyk
        </Link>
      </button>
    </div>
  );
}
